.filtrate-page-m {
  padding: 0 16px;
  position: sticky;
  // top: 102px;
  z-index: 99;
  background: #fff;
  // transition: top 0.5s ease
}

.filtrate-action-m {
  flex-wrap: wrap;

  &.filtrate-m {
    height: 50px;
  }

  &.border {
    border-top: 1px solid rgba(189, 189, 189, 1);
    border-bottom: 1px solid rgba(189, 189, 189, 1);
    font-weight: 600;
  }

  .icon-filtrate {
    margin-left: 12px;
    font-size: 14px;
  }

  .tag-box {
    margin-top: 4px;
    margin-right: 4px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    box-sizing: border-box;
    background: #f5f5f5;

    font-family: 'FZLanTingHeiS-R-GB';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    text-align: center;
    letter-spacing: 0.466667px;

    color: #212121;

    .tag-label {
      margin-left: 8px;
    }
  }
}

.clear-btn {
  box-sizing: border-box;
  padding: 0 26px;

  height: 32px;

  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.2);

  font-family: 'FZLanTingHeiS-R-GB';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;

  text-align: center;
  letter-spacing: 0.466667px;

  color: #212121;
}

.plp-drawer-body-m {
  z-index: 1008;

  .ant-drawer-body {
    padding: 0;
  }

  .drawer-container {
    position: relative;
    height: 100%;
    padding: 0 0 50px;

    display: flex;
    flex-direction: column;

    .drawer-head-box {
      padding: 16px 20px;
    }

    .drawer-main {
      overflow: auto;

      .ant-collapse-item {
        padding: 32px 0;
        border-top: 1px solid rgba(0, 0, 0, 0.08);

        .ant-collapse-header {
          font-weight: 600;
          font-size: 16px;
          color: #212121;
        }
      }
    }

    .drawer-footer {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50px;

      background: #212121;

      font-family: 'FZLanTingHei-M-GBK';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 50px;

      letter-spacing: 0.466667px;
      color: #ffffff;
      text-align: center;

      &.gray {
        pointer-events: none;
        background: #fafafa;
        color: rgba(0, 0, 0, 0.4);
        border-top: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
  }

  .collapse-box-m {
    .ant-collapse-header {
      padding: 0;
      position: relative;

      .ant-collapse-arrow {
        right: 0;
      }
    }

    .ant-collapse-content-box {
      padding: 32px 0 0 !important;
    }
  }
}

.checkbox-page {
  .tabs-container {
    font-family: 'FZLanTingHeiS-R-GB';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    letter-spacing: 0.466667px;

    color: #212121;

    .name {
      margin: 0 4px;
    }
  }

  .checkbox-list-m {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    grid-gap: 26px 0;

    &.size {
      grid-template-columns: repeat(4, 22.9%);
      grid-gap: 12px 3%;
    }

    .item {
      display: flex;
      align-items: flex-start;
      box-sizing: border-box;

      font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      letter-spacing: 0.466667px;

      color: rgba(33, 33, 33, 0.6);

      .item-content {
        display: flex;
        width: 100%;
        word-break: break-all;
      }

      .icon-img {
        margin-right: 8px;

        &.color_filter {
          border: 1px solid transparent;

          &.color-active {
            border: 1px solid #212121;
          }
        }

        &.White {
          border: 1px solid rgba(33, 33, 33, 0.3);
        }
      }

      &.active {
        color: rgba(33, 33, 33, 1);
      }

      &.disabled {
        pointer-events: none;
        color: rgba(33, 33, 33, 0.2) !important;

        img {
          opacity: 0.4;
        }

        &.size {
          border: 1px solid rgba(0, 0, 0, 0.1) !important;
        }
      }

      &.size {
        justify-content: center;
        box-sizing: border-box;
        height: 76px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.2);

        font-family: 'Zona Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 76px;

        text-align: center;
        letter-spacing: 0.466667px;

        color: #212121;

        &.active {
          font-weight: 600;
          border: 1px solid #212121;
        }
      }
    }
  }

  .radioWay-list {
    .item {
      display: flex;
      align-items: center;
      height: 24px;

      font-family: 'FZLanTingHeiS-R-GB';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;

      letter-spacing: 0.466667px;

      color: rgba(33, 33, 33, 0.6);

      &.active {
        color: rgba(33, 33, 33, 1);
      }

      &:not(:nth-last-of-type(1)) {
        margin-bottom: 24px;
      }

      img {
        margin-right: 8px;
      }
    }
  }
}

.dropdown-plp {
  border: 1px solid #bdbdbd;

  .ant-dropdown-menu-item {
    padding: 16px 32px;

    font-family: 'FZLanTingHeiS-R-GB';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;

    letter-spacing: 0.466667px;

    color: rgba(33, 33, 33, 0.6);

    &.ant-dropdown-menu-item-selected {
      background-color: transparent !important;
      color: rgba(33, 33, 33, 1) !important;
    }
  }
}

// .dropdown-box {
//   height: 40px;
//   line-height: 40px;
// }